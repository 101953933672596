import React, { useState } from "react";
import styled from "styled-components/macro";
import Layout from "../components/Layout";
import {
  Container,
  Title,
  Body,
  Label,
  TextField,
  TextArea,
  PrimaryButton,
} from "../common/styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import get from "lodash/get";
import marked from "marked";

const ContactUs = ({ data }) => {
  const [state, setState] = useState({});
  const [sent, setSent] = useState(false);
  const PAGE_TITLE = get(data, "allContentfulContactUsPage.nodes[0].pageTitle");
  const PAGE_DESCRIPTION = get(
    data,
    "allContentfulContactUsPage.nodes[0].pageDescription"
  );
  const IMAGE = get(data, "allContentfulContactUsPage.nodes[0].image.file.url");
  const HEADING = get(data, "allContentfulContactUsPage.nodes[0].heading");
  const INTRO = get(
    data,
    "allContentfulContactUsPage.nodes[0].intro.childMarkdownRemark.rawMarkdownBody"
  );

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    console.log(state);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...state }),
    })
      .then(() => setSent(true))
      .catch((error) =>
        alert("Oh no! Could you try refresh the page and try again?")
      );

    e.preventDefault();
  };

  return (
    <Layout>
      <Helmet title={PAGE_TITLE}>
        <meta name="description" content={PAGE_DESCRIPTION} />
      </Helmet>
      <Container>
        <HeaderImageContainer>
          <HeaderImage src={IMAGE}></HeaderImage>
        </HeaderImageContainer>
        <Heading>{HEADING}</Heading>
        <Body>
          <Intro
            dangerouslySetInnerHTML={{
              __html: marked(INTRO || ""),
            }}></Intro>
          <ThankYouMsg active={sent}>
            Thank you! We'll be in touch soon :)
          </ThankYouMsg>
          <ContactForm
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}>
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <Label>
                Don’t fill this out: <input name="bot-field" />
              </Label>
            </p>
            <Field>
              <Label>Your Name:</Label>
              <TextField type="text" name="name" onChange={handleChange} />
            </Field>
            <Field>
              <Label>Your Email:</Label>
              <TextField type="email" name="email" onChange={handleChange} />
            </Field>
            <Field>
              <Label>Message:</Label>
              <TextArea name="message" onChange={handleChange}></TextArea>
            </Field>
            <Field>
              <PrimaryButton type="submit">Send</PrimaryButton>
            </Field>
          </ContactForm>
        </Body>
      </Container>
    </Layout>
  );
};

export default ContactUs;

const HeaderImageContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const HeaderImage = styled.img`
  height: 100%;
  max-width: 900px;
  width: 100%;
  margin: auto;
`;

const Heading = styled(Title)`
  letter-spacing: 1px;
  font-size: 4rem;
  text-align: center;
`;

const Intro = styled.div`
  flex-basis: 100%;
  max-width: 600px;
  font-size: 1.6rem;
  line-height: 2.5rem;
  letter-spacing: 0.2px;
`;

const ThankYouMsg = styled.div`
  max-width: 600px;
  flex-basis: 100%;
  border-radius: 10px;
  background-color: #d0f0c0;
  color: #01796f;
  font-size: 1.6rem;
  padding: 10px;
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: 0.2s;
`;

const ContactForm = styled.form`
  max-width: 600px;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Field = styled.p`
  width: 100%;
`;

export const pageQuery = graphql`
  {
    allContentfulContactUsPage {
      nodes {
        heading
        pageTitle
        pageDescription
        intro {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
